<template>
  <div>
    <section
      class="
      flex
      gap-x-10
      w-full
    "
    >
      <div>
        <span
          @click="changeTab('artworks')"
          class="hover__border md:text-base xl:text-lg font-semibold"
          :class="{ clicked__border: tab === 'artworks' }"
        >
          Artworks
        </span>
      </div>
      <div>
        <span
          @click="changeTab('sales')"
          class="hover__border md:text-base xl:text-lg font-semibold"
          :class="{ clicked__border: tab === 'sales' }"
        >
          On Sales
        </span>
      </div>
      <div>
        <span
          @click="changeTab('bids')"
          class="hover__border md:text-base xl:text-lg font-semibold"
          :class="{ clicked__border: tab === 'bids' }"
        >
          Bids
        </span>
      </div>
    </section>

    <hr class="tab__border" />
  </div>
</template>

<script>
export default {
  emits: {
    changeTab: {
      type: String,
    },
  },

  data() {
    return {
      tab: "artworks",
    };
  },

  methods: {
    changeTab(tab) {
      this.tab = tab;
      this.$emit("changeTab", tab);
    },
  },
};
</script>

<style scoped>
.tab__border {
  position: absolute;
  width: 100%;
  left: 0;
  border-color: #dddddd;
  z-index: -1;
}

.hover__border:hover {
  cursor: pointer;
  border-color: #dddddd;
  border-bottom-width: 0.25rem;
  border-radius: 0.125rem;
}

.clicked__border {
  border-color: #262626;
  border-bottom-width: 0.25rem;
  border-radius: 0.125rem;
}
</style>
