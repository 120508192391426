<template>
  <div>
    <!-- <order-register :user-data="userData" @on-cancel="createOrder = $event" /> -->

    <!-- normal carousel -->
    <section class="mb-24">
      <div class="flex justify-between">
        <div class="flex items-center mb-10">
          <h1
            class="
            text-lg
            sm:text-xl
            md:text-2xl
            text-center
            sm:text-left
            font-semibold
          "
          >
            Normal Sales
          </h1>
          <router-link
            to="/mypage/order/new?orderType=normal"
            class="
              text-sm
              md:text-base
              button-green
              ml-4
            "
            >Sell
          </router-link>
        </div>
      </div>

      <div v-if="isLoading.normal" class="flex justify-center items-center">
        <img
          src="@/assets/img/cilo_loading.gif"
          alt="loading"
          class="w-24 h-24"
        />
      </div>
      <div v-else>
        <div v-if="normalSalesList.length === 0">
          No items
        </div>
        <div v-else>
          <normal-carousel :items="normalSalesList" />
        </div>
      </div>
    </section>

    <!-- auction carousel -->
    <section>
      <div class="flex items-center mb-10">
        <h1
          class="
            text-lg
            sm:text-xl
            md:text-2xl
            text-center
            sm:text-left
            font-semibold
          "
        >
          Auction Sales
        </h1>
        <router-link
          to="/mypage/order/new?orderType=auction"
          class="
              text-sm
              md:text-base
              button-green
              ml-4
            "
          >Sell
        </router-link>
      </div>

      <div v-if="isLoading.auction" class="flex justify-center items-center">
        <img
          src="@/assets/img/cilo_loading.gif"
          alt="loading"
          class="w-24 h-24"
        />
      </div>
      <div v-else>
        <div v-if="auctionList.length === 0">
          No items
        </div>
        <div v-else>
          <normal-carousel :items="auctionList" :card-type="'auction'" />
        </div>
      </div>
    </section>

    <!-- <section class="flex justify-center mt-20">
      <router-link
        to="/mypage/order/new"
        class="
          py-2
          px-4
          bg-white
          border border-black
          hover:bg-black hover:text-white
          text-black text-xs
          md:text-base
          button-black
          flex
          justify-center
          items-center
        "
        >Sell
      </router-link>
    </section> -->
  </div>
</template>

<script>
// import OrderRegister from "@/views/my-page/sub/OrderRegister.vue";
import NormalCarousel from "@/components/slide-list/NormalCarousel.vue";

import OpenapiService from "@/services/openapi.service";
import SessionService from "@/services/session.service";
import { notify } from "@kyvg/vue3-notification";
import { removeUnusedZero, setVisualPrice } from "@/util/price";

export default {
  data() {
    return {
      isLoading: {
        normal: false,
        auction: false,
      },
      DISPLAYING_DIGITS: 3,

      // view page
      createOrder: false,
      orderType: "",

      // variables
      userNickname: "",
      userId: "",
      userData: {},

      normalSalesList: [],
      auctionList: [],
    };
  },

  components: {
    // OrderRegister,
    NormalCarousel,
  },

  async created() {
    // user info
    this.userNickname = await SessionService.getNickname();
    this.userId = await SessionService.getUserId();
    this.userData = await this.loadUser();

    this.getNormalSalesList();
    this.getAuctionSalesList();
  },

  methods: {
    loadUser() {
      return new Promise((resolve) => {
        OpenapiService.userById(this.userId)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            let error = e;
            if (e.response && e.response.data) error = e.response.data;
            let msg = `user 정보 조회 실패, (error: ${error}`;
            notify({ type: "error", text: msg });
          });
      });
    },

    async getNormalSalesList() {
      this.isLoading.normal = true;
      this.normalSalesList = await this.getSalesList("fixedPriceOnly", "onSales");
      this.isLoading.normal = false;
    },

    async getAuctionSalesList() {
      this.isLoading.auction = true;
      this.auctionList = await this.getSalesList("auctionOnly", "onSales");
      this.isLoading.auction = false;
    },

    getSalesList(saleType, orderType) {
      return new Promise((resolve) => {
        OpenapiService.getUserSalesInfo(this.userNickname, saleType, orderType)
          .then((res) => {
            console.log("res ==> ", res);
            const { items } = res.data;

            for (var i = 0; i < items.length; i++) {
              if (items[i].order_type === 1) {
                //일반판매일경우
                items[i].num_price = removeUnusedZero(
                  items[i].price_fixed,
                  items[i].assetDecimals
                );
                items[i].visual_price = setVisualPrice(
                  items[i].num_price,
                  this.DISPLAYING_DIGITS
                );
              } else {
                //경매일경우
                items[i].num_price = removeUnusedZero(
                  items[i].price_begin,
                  items[i].assetDecimals
                );
                items[i].visual_price = setVisualPrice(
                  items[i].num_price,
                  this.DISPLAYING_DIGITS
                );
              }
            }

            resolve(items);
          })
          .catch((e) => {
            notify({ type: "error", text: e.message });
          });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/_layout.scss";
@import "@/scss/_text.scss";
@import "@/scss/_variables.scss";
@import "@/scss/_button.scss";

#logo-other {
  display: inline-block;
}

.button-black {
  @include button-radius(8rem, 2.5rem, $black);
}

.layout__spotlight {
  min-height: 25rem;
  height: calc(100vh - 70px);
}

.layout__carousel__main {
  min-height: 26rem;
  height: 70vh;
  padding: 2.5rem 0px;
  background-color: #f8f8f8;
}

.carousel__viewport {
  padding: 0 0 1.5rem 0;
}

.carousel__prev--in-active,
.carousel__next--in-active {
  display: none;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  background-color: transparent;
  border: 1px solid $black;
  border-radius: 50%;
}

.carousel__icon {
  fill: #262626;
}

.videoForm {
  height: 92vh;
}

.button-green {
  font-weight: 600;
  @include button-white-radius(5rem, 2rem, #20A97F, #20A97F);
  // &:hover {
  //   @include button-radius(10rem, 2rem, $black, $white);
  //   border: 1px, solid, $black;
  // }
}
</style>
