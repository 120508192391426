import axios from "axios";

export default {
  media(file, owner_nickname, sub_bucket, info, reader, stage) {
    const self = this;
    return new Promise((resolve, reject) => {
      self
        .api(null)
        .post(
          "/upload/media",
          JSON.stringify({
            name: file.name,
            type: file.type,
            owner_nickname: owner_nickname,
            sub_bucket: sub_bucket,
            mediainfo: info,
            path: file.path,
            stage: stage,
          })
        )
        .then(function(res) {
          var options = {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "HEAD,GET,PUT,POST,DELETE",
              "x-amz-acl": "public-read",
            },
          };

          // const uploadUrl1 = res.data.uploadUrl;
          // const result1 = reader.result;

          self
            .aws_api(file.type)
            .put(res.data.uploadUrl, reader.result, options)
            .then(function(uploaded) {
              // console.log(uploaded);

              if (uploaded.status == 200) {
                // console.log("exec media upload  step 003");
                // alert('upload complete !!')
                // $('#loading_circle').hide()
                resolve(res.data);
              }
            })
            .catch(function(err) {
              // console.log(err);
              reject(err);
            });
        })
        .catch(function(err) {
          // console.log(err);
          reject(err);
        });
    });
  },

  updateSate(id) {
    // console.log("updateState id ==> ", id);
    return this.api(null).put(`/upload/${id}`, { id: id });
  },

  //api()
  // api() {
  //   return axios.create({
  //     baseURL: process.env.VUE_APP_UPLOAD_URL,
  //     withCredentials: false,
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //   });
  // },

  api(filetype) {
    if (filetype) {
      // console.log("filetype is not null==> ", filetype);
      return axios.create({
        baseURL: process.env.VUE_APP_UPLOAD_URL,
        withCredentials: false,
        headers: {
          Accept: "application/json",
          "Content-Type": filetype,
        },
      });
    } else {
      // console.log("filetype is null==> ", filetype);
      return axios.create({
        baseURL: process.env.VUE_APP_UPLOAD_URL,
        withCredentials: false,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
    }
  },

  aws_api(filetype) {
    if (filetype) {
      // console.log("filetype is not null==> ", filetype);
      return axios.create({
        baseURL: process.env.VUE_APP_UPLOAD_URL,
        withCredentials: false,
        headers: {
          Accept: "application/json",
          "Content-Type": filetype,
        },
      });
    } else {
      // console.log("filetype is null==> ", filetype);
      return axios.create({
        baseURL: process.env.VUE_APP_UPLOAD_URL,
        withCredentials: false,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
    }
  },
};
