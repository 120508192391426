<template>
  <div class="layout__bids">
    <section class="layout__bids__tab">
      <div class="h-full flex items-center gap-x-2">
        <button
          @click="[(tab = 'onAuction'), (selectedBid = {}), (bids = [])]"
          :class="{ clicked__tab: tab === 'onAuction' }"
        >
          Live
        </button>
        <button
          @click="[(tab = 'closed'), (selectedBid = {}), (bids = [])]"
          :class="{ clicked__tab: tab === 'closed' }"
        >
          End
        </button>
        <button
          @click="[(tab = 'matched'), (selectedBid = {}), (bids = [])]"
          :class="{ clicked__tab: tab === 'matched' }"
        >
          Matched
        </button>
        <button
          @click="[(tab = 'hold'), (selectedBid = {}), (bids = [])]"
          :class="{ clicked__tab: tab === 'hold' }"
        >
          Hold
        </button>
      </div>
    </section>

    <section class="hidden md:block"></section>

    <section class="layout__bids__list">
      <div class="bids__list__card overflow-y-auto overflow-hidden">
        <div v-if="bidList.length < 1">
          No Data
        </div>
        <div
          v-else
          v-for="(bid, index) in bidList"
          :key="index"
          @click="onBidClick(bid)"
          class="bids__list__card__info items-center"
          :class="{ clicked__card: selectedBid.id === bid.id }"
        >
          <div class="viewer__container">
            <content-viewer
              :file-path="bid.thumbnail"
              class="w-full h-full"
              shape="rounded-lg"
            />
          </div>

          <div class="description">
            <div class="parula__text-black font-semibold text__ellipsis">
              {{ bid.title }}
            </div>
            <div class="parula__text-gray font-semibold text-sm text__ellipsis">
              @{{ bid.pen_name }}
            </div>
          </div>

          <div class="bid_cnt">
            {{ bid.bid_cnt }}
          </div>
        </div>
      </div>
    </section>

    <section class="layout__bids__detail">
      <div class="layout__bids__detail__card mb-5">
        <div class="flex items-center" v-if="selectedBid.id">
          <div class="detail-info">
            <content-viewer
              :file-path="selectedBid.image"
              class="w-full h-full"
              shape="rounded-lg"
            />
          </div>
          <div>
            <div class="parula__text-black font-semibold">
              {{ selectedBid.title }}
            </div>
            <div class="parula__text-gray font-semibold text-sm">
              @{{ selectedBid.pen_name }}
            </div>
            <div
              class="flex items-center parula__text-gray font-semibold text-sm symbol"
            >
              Reserved Price ⎮ {{ selectedBid.visual_reserve_price }}
              <img
                :src="selectedBid.asset_thumbnail"
                alt="symbol"
                class="ml-1 w-4 h-4"
                v-if="selectedBid.visual_reserve_price !== 'null'"
              />
            </div>
          </div>
          <div class="ml-auto">
            <div class="flex gap-x-3">
              <img
                src="@/assets/img/refresh.png"
                alt="refresh"
                class="w-5 h-5 cursor-pointer"
                @click="onBidClick(selectedBid)"
              />

              <nav v-if="tab !== 'matched'" class="relative">
                <img
                  src="@/assets/img/ellipsis.png"
                  alt="ellipsis"
                  class="w-5 h-5 cursor-pointer"
                  @click="onDropdownClick"
                  v-click-outside="onClickOutside"
                />

                <ul class="dropdown" ref="Dropdown">
                  <li
                    v-if="selectedBid.order_state === 1"
                    class="dropdown__item"
                    @click="onChangeOrderState('hold')"
                  >
                    Hold Order
                  </li>
                  <li
                    v-if="selectedBid.order_state === 2"
                    class="dropdown__item"
                    @click="onChangeOrderState('closed')"
                  >
                    Close Order
                  </li>
                  <li
                    v-if="selectedBid.order_state === 2"
                    class="dropdown__item"
                    @click="onChangeOrderState('onSales')"
                  >
                    Open Order
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div v-if="bids.length === 0">
        No Data
      </div>

      <div v-else v-for="(bid, index) in bids" :key="index" class="bids">
        <div class="flex">
          <div class="bidder__avatar">
            <content-viewer
              :file-path="bid.avatar"
              class="w-full h-full"
              shape="rounded-full"
            />
          </div>
          <div class="bids-info parula__text-black text-sm">
            <div class="font-semibold text__ellipsis">@{{ bid.nickname }}</div>
            <div class="text__ellipsis">{{ bid.mail }}</div>
            <div class="parula__text-gray text__ellipsis">
              {{ bid.order_wallet }}
            </div>

            <div class="flex mt-2">
              <p class="font-semibold w-20">Bid Price</p>
              <div class="flex items-center">
                {{ bid.visual_price }}
                <img
                  :src="selectedBid.asset_thumbnail"
                  alt="symbol"
                  class="ml-1  w-4 h-4"
                />
              </div>
            </div>

            <div v-if="bid.order_state === 1" class="flex mb-4">
              <p class="font-semibold w-20">Balance</p>
              <div class="flex items-center" v-if="bid.balance">
                {{ bid.balance }}
                <img
                  :src="selectedBid.asset_thumbnail"
                  alt="symbol"
                  class="ml-1 w-4 h-4"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="flex gap-x-2 justify-end">
          <button
            class="button-green text-sm"
            v-if="bid.order_state === 1"
            @click="onBalanceCheck(bid)"
          >
            Check Balance
          </button>

          <!-- v-if="bid.order_state === 1 && tab === 'closed'" -->
          <button
            v-if="bid.order_state === 1 && tab === 'closed'"
            class="button-green text-sm"
            @click="onWinBid(bid)"
          >
            Win a Bid
          </button>
        </div>
      </div>
    </section>

    <confirm-modal
      ref="OrderStateChangeModal"
      :msg="confirmModalMessage"
      :modalFunction="onConfirmModalExecute"
    />
    <result-modal
      ref="ResultModal"
      :msg="resultMessage"
      :modalFunction="onResultModalExecute"
    />
    <result-modal
      ref="BidMatchResultModal"
      :msg="bidMatchModalMessage"
      :modalFunction="onResultModalExecute"
    />

    <loading-modal ref="LoadingModal" />
  </div>
</template>

<script>
import OpenapiAuthService from "@/services/openapiAuth.service";
import OpenapiService from "@/services/openapi.service";
import SessionService from "@/services/session.service";

import ContentViewer from "@/components/ContentViewer.vue";
import { Parula } from "@/parula-js/src/parula.js";
import { notify } from "@kyvg/vue3-notification";
import { removeUnusedZero, handleZeroDB, setVisualPrice } from "@/util/price";
import { getCurrentMetamaskNetworkId } from "@/util/wallet";

import ConfirmModal from "@/components/modal/ConfirmModal.vue";
import ResultModal from "@/components/modal/ResultModal.vue";
import LoadingModal from "@/components/modal/LoadingModal.vue";
import vClickOutside from "click-outside-vue3";

export default {
  data() {
    return {
      DISPLAYING_DIGITS: 3,
      sellerId: "",
      pic: "",

      // modal
      changedOrderType: "",
      bidMatchModalMessage: "",
      confirmModalMessage: "",
      resultMessage: "",

      tab: "onAuction",
      bidList: [],
      selectedBid: {},
      bids: [],
      orderDetail: {},
    };
  },

  components: {
    ContentViewer,
    ConfirmModal,
    ResultModal,
    LoadingModal,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  async created() {
    this.sellerId = await SessionService.getUserId();
    this.load();
  },

  watch: {
    tab() {
      this.load();
    },
  },

  methods: {
    load() {
      let param = {
        sortBy: "newest",
      };

      if (this.tab) {
        // onAuction, closed, matched, hold
        param["auctionStateBy"] = this.tab;
      }

      OpenapiAuthService.getJoinBidList(0, 1000, param, this.sellerId).then(
        (res) => {
          const { items } = res.data;

          this.bidList = items;
          this.selectedBid = items.length < 1 ? [] : items[0];
        }
      );
    },

    async onBidClick(bid) {
      this.selectedBid = bid;

      this.selectedBid.num_reserve_price = removeUnusedZero(
        bid.reserve_price,
        bid.asset_decimals
      );
      this.selectedBid.visual_reserve_price = setVisualPrice(
        bid.num_reserve_price,
        this.DISPLAYING_DIGITS
      );

      await OpenapiAuthService.getBidListWithAsset(bid.id)
        .then((res) => {
          console.log("bids => ", res);
          this.bids = res.data.items;

          for (var i = 0; i < this.bids.length; i++) {
            this.bids[i].num_price = removeUnusedZero(
              this.bids[i].price,
              bid.asset_decimals
            );
            this.bids[i].visual_price = setVisualPrice(
              this.bids[i].num_price,
              this.DISPLAYING_DIGITS
            );

            // this.bids[i].asset_thumbnail = bid.asset_thumbnail;
          }
        })
        .catch((error) => {
          console.error(error);
        });

      await OpenapiService.orderGet(bid.id).then((res) => {
        this.orderDetail = res.data;
        console.log("order detail => ", this.orderDetail);
      });
    },

    onChangeOrderState(changedOrderType) {
      const { title } = this.selectedBid;
      this.changedOrderType = changedOrderType;

      this.confirmModalMessage = `Do you really want to ${changedOrderType} '${title}?'`;
      this.$refs.OrderStateChangeModal.open();
    },

    onConfirmModalExecute() {
      const { id } = this.selectedBid;

      OpenapiAuthService.updateState(id, this.changedOrderType)
        .then(() => {
          this.resultMessage = `The order has successfully ${this.changedOrderType}`;
          this.$refs.OrderStateChangeModal.close();
          this.$refs.ResultModal.open();
        })
        .catch((error) => {
          console.error(error);

          this.resultMessage = `Error Occur: ${error.message}`;
          this.$refs.OrderStateChangeModal.close();
          this.$refs.ResultModal.open();
        });
    },

    onResultModalExecute() {
      this.load();
      this.$refs.ResultModal.close();
    },

    onDropdownClick() {
      const { height } = this.$refs.Dropdown.style;
      const { length } = document.querySelectorAll(".dropdown__item");

      // if (!height) {
      //   height = "2.5rem";
      //   this.$refs.Dropdown.style.height = height;
      // } else {
      //   height = "";
      //   this.$refs.Dropdown.style.height = height;
      // }

      // 위 아래 동일한 코드
      // calc(2.5rem * x)에서 x <- li 갯수
      this.$refs.Dropdown.style.height = !height
        ? `calc(2.5rem * ${length})`
        : "";
    },

    onClickOutside() {
      this.$refs.Dropdown.style.height = "";
    },

    async onBalanceCheck(bid) {
      const currentMetamaskNetworkId = await getCurrentMetamaskNetworkId();
      const { network_id, blockchain_name } = bid;

      if (currentMetamaskNetworkId !== network_id) {
        notify({
          type: "error",
          text: `Your wallet's network is not ${blockchain_name}`,
        });
        return;
      }

      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const account = accounts[0];
        console.log("bid", bid);
        const balanceABI = [
          {
            constant: true,
            inputs: [{ name: "_owner", type: "address" }],
            name: "balanceOf",
            outputs: [{ name: "balance", type: "uint256" }],
            type: "function",
          },
        ];
        console.log("bid.order_wallet", bid.order_wallet);
        if (bid.order_wallet) {
          // const parula = new Parula(window.ethereum, {}, line=>console.info('[*] ',line));
          let config = {};
          let provider = window.ethereum;
          const parula = new Parula(provider, config, (line) =>
            console.info(`${config.networkName}:: ${line}`)
          );
          const contract = new parula.web3.eth.Contract(
            balanceABI,
            bid.asset_address
          );
          console.log("contract", contract);
          const weiBal = await contract.methods
            .balanceOf(bid.order_wallet)
            .call();
          console.log("weiBal", weiBal);
          const ethBal = parula.web3.utils.fromWei(weiBal, "ether");
          console.log("ethBal", ethBal);
          console.log("result::::", ethBal, weiBal);
          bid.balance = ethBal;
        }
      } catch (e) {
        // notify({ type: "error", text: e });
      }
    },

    // onWinBid(bid) {
    //   console.log(bid);

    //   this.bidMatchModalMessage = `Do you win a bid to  ${bid.nickname} for ${bid.num_price} ${bid.symbol}`;
    //   this.$refs.BidMatchModal.open();

    //   // this.windBid(bid);
    // },

    async onWinBid(bid) {
      this.$refs.LoadingModal.open();

      const currentMetamaskNetworkId = await getCurrentMetamaskNetworkId();
      const { network_id, blockchain_name } = bid;

      if (currentMetamaskNetworkId !== network_id) {
        notify({
          type: "error",
          text: `Your wallet's network is not ${blockchain_name}`,
        });
        return;
      }

      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      const adminAccount = accounts[0];

      const parsed_price = removeUnusedZero(
        bid.price,
        this.orderDetail.decimals
      );

      const total_price = handleZeroDB(parsed_price, this.orderDetail.decimals);

      console.log("total_price", total_price);

      const parula = new Parula(window.ethereum, {}, (line) =>
        console.info("[*] ", line)
      );

      const bidParams = {
        maker: adminAccount,
        maTypeMV: this.orderDetail.maTypeMV,
        maContractAddress: this.orderDetail.maContAddr,
        maValue: this.orderDetail.maTokenId,
        bidId: bid.id,
      };
      console.log("bidParams::", bidParams);
      var ret = await parula.createBidMatch(bidParams);

      if (ret.status === false) {
        console.error("error", ret.tx.err);
        notify({ type: "error", text: "parula.createBidMatch Fail" });
        this.$refs.LoadingModal.close();
        return;
      }

      OpenapiAuthService.orderSold(
        bid.order_id,
        bid.bidder_id,
        bid.price,
        total_price,
        11,
        21,
        this.orderDetail.quantity,
        bid.order_wallet,
        ret.tx
      )
        .then(() => {
          this.$refs.LoadingModal.close();
          this.bidMatchModalMessage = `Successfully sold by ${bid.nickname} for ${bid.num_price} ${bid.symbol}`;
          this.$refs.BidMatchResultModal.open();
          this.postSoldMail(bid);
        })
        .catch((e) => {
          notify({ type: "error", text: e.message });
          this.$refs.LoadingModal.close();
          return;
        });
    },

    postSoldMail(bidInfo) {
      const {
        id: order_id,
        creator_id,
        pen_name: creator_pen_name,
        seller_id,
        seller_nickname,
        seller_mail,
        order_wallet,
        thumbnail: product_thumbnail,
        title: product_name,
        asset_symbol: taSymbol,
        remain,
        // total_supply,
        asset_decimals,
      } = this.selectedBid;

      const {
        bidder_id: buyer_id,
        nickname: buyer_nickname,
        mail: buyer_mail,
        price, // 입찰가 (현재는 이름만 price_fixed로 넘김)
      } = bidInfo;

      const msg = `sold null ${Date.parse(new Date())}`;
      const stage = `${process.env.VUE_APP_STAGE}`;

      const price_fixed = removeUnusedZero(price, asset_decimals);

      const params = {
        order_id,
        creator_id,
        creator_pen_name,
        seller_id,
        seller_nickname,
        seller_mail,
        buyer_id,
        buyer_nickname,
        buyer_mail,
        order_wallet,
        product_thumbnail,
        product_name,
        price_fixed,
        taSymbol,
        remain,
        total_supply: 1,
        quantity: 1,
        auth_params: { msg },
        stage,
      };

      console.log("params ::::", params);

      // const authParams = { msg };

      // receiver: "seller" or "buyer"
      OpenapiAuthService.soldMail(params)
        .then((res) => {
          console.log("sold-mail res ::::", res);
          // Notify.success("Success", "Bid matched successfully.");
          this.$router.go();
        })
        .catch((e) => {
          console.error(e.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_button.scss";
@import "@/scss/_text.scss";
@import "@/scss/_scrollbar.scss";
@import "@/scss/_layout.scss";

.layout__bids {
  display: grid;
  gap: 2rem;

  @include sm {
    grid-template-columns: 1fr;
  }

  @include min_md {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 3rem 1fr;
    min-height: 50vh;
  }

  .layout__bids__tab {
    button {
      font-size: 0.75rem;
      font-weight: 600;
      border: 1px solid $black;
      @include button-radius(5rem, 2.5rem, $white, $black);

      &:hover {
        background-color: $black;
        color: $white;
      }
    }

    .clicked__tab {
      background-color: $black;
      color: $white;
    }
  }

  .layout__bids__list {
    overflow-x: hidden;

    @include sm {
      max-height: 35vh;
    }

    @include min-md {
      min-height: 20rem;
      height: 50vh;
    }

    .bids__list__card {
      padding: 1rem;
      background-color: $gray-soft2;
      border-radius: $radius;

      .bids__list__card__info {
        position: relative;
        cursor: pointer;
        display: flex;
        padding: 0.75rem;
        margin-bottom: 0.25rem;
        border: 1px solid $gray;
        border-radius: $radius;
        background-color: $white;
        &:hover {
          border: 1px solid $blue;
          .bid_cnt {
            background-color: $blue;
          }
        }

        .viewer__container {
          width: 3.375rem;
          height: 3.375rem;
          object-fit: cover;
          border-radius: $radius;
        }

        .bid_cnt {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.75rem;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%;
          right: -0.75rem;
          background-color: $gray;
          color: $white;
        }

        .description {
          margin: auto 0;
          width: calc(100% - 3.375rem);
          padding: 0 0.5rem;
        }
      }

      .clicked__card {
        border: 1px solid $blue;
        .bid_cnt {
          background-color: $blue;
        }
      }
    }
  }

  .layout__bids__detail {
    padding: 1.5rem;
    background-color: $gray-soft2;
    border-radius: $radius;
    overflow-x: hidden;

    .layout__bids__detail__card {
      margin-bottom: 1.5rem;

      .detail-info {
        width: 5rem;
        height: 5rem;
        object-fit: cover;
        margin-right: 1.5rem;
      }

      nav {
        $dropdown-item-height: 2.5rem;

        // &:hover {
        //   .dropdown {
        //     height: 2.5rem * 1;
        //     background-color: $white;
        //   }
        // }

        .dropdown {
          overflow: hidden;
          position: absolute;
          top: 2rem;
          right: 0;

          width: 10rem;
          height: 0rem;
          border-radius: 0.5rem;
          box-sizing: border-box;
          transition: all 0.3s ease-in-out;
          transition-delay: 0.25s;

          // border: 1px solid $gray-soft;
          background-color: $white;

          .dropdown__item {
            display: flex;
            align-items: center;

            font-size: small;
            font-weight: 600;
            height: $dropdown-item-height;
            padding: 0.25rem 1rem;
            box-sizing: border-box;
            background-color: $white;

            &:hover {
              cursor: pointer;
              background-color: $gray-soft;
              color: $white;
            }
          }
        }
      }
    }

    .bids {
      padding: 1.5rem;
      margin-bottom: 0.5rem;
      background-color: #fff;
      border-radius: $radius;

      .bidder__avatar {
        width: 5rem;
        height: 5rem;
        margin-right: 2rem;
      }

      .bids-info {
        width: calc(100% - 7rem);
      }
    }
  }
}

.button-green {
  font-weight: 600;
  @include button-white-radius(8rem, 2rem, #20A97F, #20A97F);
  // &:hover {
  //   @include button-radius(10rem, 2rem, $black, $white);
  //   border: 1px, solid, $black;
  // }
}
// .text__ellipsis {
//   @extend %text__ellipsis;
// }
</style>
