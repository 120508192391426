<template>
  <div>
    <notifications position="bottom right" />

    <div class="flex flex-col">
      <section class="flex flex-col gap-y-24">
        <div>
          <div class="flex flex-col sm:flex-row justify-between items-center">
            <div class="flex items-center mb-10">
              <h1
                class="
                text-lg
                sm:text-xl
                md:text-2xl
                text-center
                sm:text-left
                font-semibold
              "
              >
                My Collection
              </h1>

              <router-link
                v-if="UserData.is_creator === 1"
                to="/mypage/product/mint"
                class="
                  ml-4
                  text-sm
                  md:text-base
                  button-green
                "
                >Create NFT
              </router-link>
              <VueCustomTooltip label="If you want to NFT minting your artwork, complete verification as an artist." 
              position="is-top">
                <router-link
                  v-if="UserData.is_creator !== 1"
                  to=""
                  class="
                    ml-4
                    text-sm
                    md:text-base
                    button-gray
                  "
                  >
                    Create NFT
                </router-link>
              </VueCustomTooltip>
            </div>

            <div class="hidden sm:flex">
              <select
                v-model="sort.collection"
                @change="onCollectionReload"
                class="border border-gray-600 text-gray-500 w-28 mr-2"
              >
                <option value="newest">Newest</option>
                <option value="oldest">Oldest</option>
              </select>
            </div>
          </div>

          <div
            v-if="isLoading.collection"
            class="flex justify-center items-center"
          >
            <img
              src="@/assets/img/cilo_loading.gif"
              alt="loading"
              class="w-24 h-24"
            />
          </div>
          <div v-else>
            <div v-if="CollectionList.length < 1">
              No items
            </div>
            <div v-else>
              <normal-carousel
                :items="CollectionList"
                link-to="product"
                card-type="product"
              />
            </div>
          </div>
        </div>

        <div>
          <div class="flex flex-col sm:flex-row justify-between items-center">
            <h1
              class="
                text-lg
                sm:text-xl
                md:text-2xl
                text-center
                sm:text-left
                font-semibold
                mb-10
              "
            >
              Auction
            </h1>
            <div class="hidden sm:flex">
              <select
                v-model="sort.auction"
                @change="onAuctionReload"
                class="border border-gray-600 text-gray-500 w-28 mr-2"
              >
                <option value="newest">Newest</option>
                <option value="oldest">Oldest</option>
              </select>
            </div>
          </div>

          <div
            v-if="isLoading.auction"
            class="flex justify-center items-center"
          >
            <img
              src="@/assets/img/cilo_loading.gif"
              alt="loading"
              class="w-24 h-24"
            />
          </div>
          <div v-else>
            <div v-if="AuctionList.length < 1">
              No items
            </div>
            <div v-else>
              <normal-carousel
                :items="AuctionList"
                link-to="order"
                card-type="auction-status"
              />
            </div>
          </div>
        </div>

        <div>
          <div class="flex flex-col sm:flex-row justify-between items-center">
            <h1
              class="
                text-lg
                sm:text-xl
                md:text-2xl
                text-center
                sm:text-left
                font-semibold
                mb-10
              "
            >
              Purchase History
            </h1>
            <div class="hidden sm:flex">
              <select
                v-model="sort.history"
                @change="onHistoryReload"
                class="border border-gray-600 text-gray-500 w-28 mr-2"
              >
                <option value="newest">Newest</option>
                <option value="oldest">Oldest</option>
              </select>
            </div>
          </div>

          <div
            v-if="isLoading.history"
            class="flex justify-center items-center"
          >
            <img
              src="@/assets/img/cilo_loading.gif"
              alt="loading"
              class="w-24 h-24"
            />
          </div>
          <div v-else>
            <div v-if="PurchaseList.length < 1">
              No items
            </div>
            <div v-else>
              <normal-carousel
                :items="PurchaseList"
                link-to="product"
                card-type="product"
              />
            </div>
          </div>
        </div>

        <div>
          <div class="flex flex-col sm:flex-row justify-between items-center">
            <h1
              class="
                text-lg
                sm:text-xl
                md:text-2xl
                text-center
                sm:text-left
                font-semibold
                mb-10
              "
            >
              Like
            </h1>
            <div class="hidden sm:flex">
              <select
                v-model="sort.like"
                @change="onLikedReload"
                class="border border-gray-600 text-gray-500 w-28 mr-2"
              >
                <option value="newest">Newest</option>
                <option value="oldest">Oldest</option>
              </select>
            </div>
          </div>

          <div v-if="isLoading.like" class="flex justify-center items-center">
            <img
              src="@/assets/img/cilo_loading.gif"
              alt="loading"
              class="w-24 h-24"
            />
          </div>
          <div v-else>
            <div v-if="LikedList.length < 1">
              No items
            </div>
            <div v-else>
              <normal-carousel
                :items="LikedList"
                link-to="product"
                card-type="product"
              />
            </div>
          </div>
        </div>
      </section>

      <!-- <section class="flex justify-center mt-20">
        <router-link
          v-if="UserData.is_creator === 1"
          to="/mypage/product/mint"
          class="
          py-2
          px-4
          bg-white
          border border-black
          hover:bg-black hover:text-white
          text-black text-xs
          md:text-base
          button-black
          flex
          justify-center
          items-center
        "
          >Create NFT
        </router-link>
      </section> -->
    </div>

    <ProfilePopup ref="profilePopup" :userInfos="UserData" />
    
  </div>
</template>

<script>
import OpenapiService from "@/services/openapi.service";
import SessionService from "@/services/session.service";
import ProfilePopup from "@/views/my-page/sub/ProfilePopup.vue";
import { notify } from "@kyvg/vue3-notification";

import NormalCarousel from "@/components/slide-list/NormalCarousel.vue";
import { removeUnusedZero, setVisualPrice } from "@/util/price";

import VueCustomTooltip from "@adamdehaven/vue-custom-tooltip";

export default {
  data: function() {
    return {
      isLoading: {
        collection: false,
        auction: false,
        history: false,
        like: false,
      },
      DISPLAYING_DIGITS: 3,

      ListTypes: ["Collection", "History", "Auction", "Like"],
      CollectionList: [],
      AuctionList: [],
      PurchaseList: [],
      LikedList: [],

      UserData: {},
      userNickname: null,
      userId: null,

      sort: {
        collection: "newest",
        auction: "newest",
        history: "newest",
        like: "newest",
      },
    };
  },

  components: {
    ProfilePopup,
    NormalCarousel,
    VueCustomTooltip,
  },

  created: async function() {
    this.userNickname = await SessionService.getNickname();
    this.userId = await SessionService.getUserId();
    this.load();
  },

  methods: {
    load: function() {
      this.loadUser();
      this.onCollectionReload();
      this.onAuctionReload();
      this.onHistoryReload();
      this.onLikedReload();
    },

    loadUser: function() {
      OpenapiService.userById(this.userId)
        .then((res) => {
          this.UserData = res.data;
        })
        .catch((e) => {
          let error = e;
          if (e.response && e.response.data) error = e.response.data;
          let msg = `user 정보 조회 실패, (error: ${error}`;
          notify({ type: "error", text: msg });
        });
    },

    onCollectionReload: function() {
      this.isLoading.collection = true;

      OpenapiService.getUserArtworkInfo(
        this.userNickname,
        "collection",
        this.sort.collection
      ).then((res) => {
        this.CollectionList = res.data.items;
        this.isLoading.collection = false;
      });
    },

    onAuctionReload: function() {
      this.isLoading.auction = true;

      OpenapiService.getUserArtworkInfo(
        this.userNickname,
        "bids",
        this.sort.auction
      ).then((res) => {
        this.isLoading.auction = false;
        const { items } = res.data;

        for (let i = 0; i < items.length; i++) {
          items[i].bid_price = removeUnusedZero(
            items[i].price,
            items[i].asset_decimals
          );
          items[i].visual_bid_price = setVisualPrice(
            items[i].bid_price,
            this.DISPLAYING_DIGITS
          );
        }

        for (let j = 0; j < items.length; j++) {
          items[j].num_highest_bid_price = removeUnusedZero(
            items[j].highest_bid_price,
            items[j].asset_decimals
          );
          items[j].visual_highest_bid_price = setVisualPrice(
            items[j].num_highest_bid_price,
            this.DISPLAYING_DIGITS
          );
        }

        this.AuctionList = items;
      });
    },

    onHistoryReload: function() {
      this.isLoading.history = true;

      OpenapiService.getUserArtworkInfo(
        this.userNickname,
        "purchased",
        this.sort.history
      ).then((res) => {
        this.PurchaseList = res.data.items;
        this.isLoading.history = false;
      });
    },

    onLikedReload: function() {
      this.isLoading.like = true;

      OpenapiService.getUserArtworkInfo(
        this.userNickname,
        "liked",
        this.sort.like
      ).then((res) => {
        this.LikedList = res.data.items;
        this.isLoading.like = false;
      });
    },

    onProfile() {
      this.$refs.profilePopup.show();
    },

    onWallet() {
      this.$refs.walletSetting.show();
    },

    nonShow: function() {
      this.isHidden = !this.isHidden;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_button.scss";

#logo-other {
  display: inline-block;
}

// .button-black {
//   @include button-radius(10rem, 2.5rem, $black);
// }

.button-green {
  font-weight: 600;
  @include button-white-radius(7rem, 2rem, #20A97F, #20A97F);
  // &:hover {
  //   @include button-radius(10rem, 2rem, $black, $white);
  //   border: 1px, solid, $black;
  // }
}

.button-gray {
  font-weight: 600;
  @include button-white-radius(7rem, 2rem, $gray, $gray);
  // &:hover {
  //   @include button-radius(10rem, 2rem, $black, $white);
  //   border: 1px, solid, $black;
  // }
}
</style>
