<template>
  <div class="collector-main">
    <transition name="bounce2">
      <WalletSetting :userData="UserData" ref="WalletSetting" />
    </transition>

    <div
      class="
        flex flex-col
        justify-center
        items-center
        mx-auto
        relative
      "
    >
      <section>
        <AvatarUpload
          v-model="UserData.avatar"
          :userInfo="UserData"
          :sub_bucket="sub_bucket"
          @uploaded="imageUploaded"
        >
        </AvatarUpload>
      </section>

      <section>
        <div class="flex flex-col items-center py-5">
          <p class="parula__text-black text-lg font-semibold">
            {{ UserData.name }}
          </p>
          <p class="parula__text-gray">@{{ UserData.nickname }}</p>
        </div>
      </section>

      <section class="counter__border">
        <div class="flex items-center border-0 border-black gap-x-12">
          <div class="flex items-center">
            <p class="parula__text-black text-lg font-semibold mr-4">
              {{ collectionCnt }}
            </p>
            <p class="parula__text-gray font-semibold">Collections</p>
          </div>
          <div class="flex items-center">
            <p class="parula__text-black text-lg font-semibold mr-4">
              {{ likedCnt }}
            </p>
            <p class="parula__text-gray font-semibold">Liked</p>
          </div>
        </div>
      </section>

      <section>
        <button
          @click="onAddWallet"
          class="
            mt-12
            button-green
            font-semibold
          "
        >
          WALLET SETTING
        </button>
      </section>
    </div>
  </div>
</template>

<script>
import OpenapiService from "@/services/openapi.service";
import sessionService from "@/services/session.service";
import WalletSetting from "@/views/my-page/sub/WalletSetting.vue";
import AvatarUpload from "@/views/my-page/sub/AvatarUpload.vue";
import { notify } from "@kyvg/vue3-notification";

export default {
  props: {
    UserData: Object,
  },

  components: {
    WalletSetting,
    AvatarUpload,
  },

  data: function() {
    return {
      ds: [],
      sub_bucket: "profile",
      collectionCnt: "",
      likedCnt: "",
      avatar: "",
      avatar_min: "",
    };
  },

  created: function() {
    this.userId = sessionService.getUserId();
    this.userName = sessionService.getNickname();

    if (this.userName) {
      OpenapiService.userCollection(this.userName).then((res) => {
        this.collectionCnt = res.data.total;
      });

      OpenapiService.userLikedHistory(this.userName).then((res) => {
        this.likedCnt = res.data.total;
      });
    }
  },

  methods: {
    onAddWallet() {
      this.$refs.WalletSetting.show();
    },

    imageUploaded(param) {
      console.log("param:::", param);

      if (param.done) {
        (this.avatar = param.path),
          (this.avatar_min = param.path),
          OpenapiService.userUpdate(this.userName, this.avatar, this.avatar_min)
            .then((res) => {
              this.emitter.emit(
                "CURRENT_AVATAR",
                JSON.parse(res.config.data).avatar
              );
            })
            // .catch((e) => {});
            .catch((e) => {
              let error = e;
              if (e.response && e.response.data) error = e.response.data;
              let msg = `Failed to save avatar., (error: ${error}`;
              console.error(msg);
              notify({ type: "error", text: msg });
            });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_button.scss";

.counter__border {
  padding: 0.5rem 1rem;
  border-top: 1px solid $gray-soft;
  border-bottom: 1px solid $gray-soft;
}

.button-green {
  @include button-radius(14rem, 2.5rem, #20A97F);
}

.gray-color {
  color: #7b848c;
}
.border-edit {
  border: 0.5px solid $black;
}
.collector-main {
  width: 100%;
  /* height:60vh; */
  margin: 0;
  position: relative;
}

.left-cir {
  margin-top: 18%;
  margin-left: 5%;
  width: 12vw;
  height: 12vw;
  border-radius: 10vw;
  background: #ccc;
}
.count {
  display: inline-flex;
  /* margin-top: 5%; */
}
.count > div {
  font-size: 14px;
  font-weight: 800;
  color: #7b848c;
}
.count p {
  font-size: 24px;
  font-weight: 600;
  color: #262626;
}

.collector-navi > div:nth-child(1) img {
  width: 35px;
  height: 35px;
  /* margin:0 auto; */
}
.collector-navi > div:nth-child(2) img {
  width: 35px;
  height: 35px;
  margin: 0 auto;
}
.collector-navi > div:nth-child(1) > svg {
  margin: 0 auto;
  margin-top: 20%;
}
.collector-navi > div:nth-child(2) > svg {
  margin: 0 auto;
  margin-top: 20%;
}
.collector-navi > div:nth-child(3) > svg {
  margin-top: 17%;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.3s reverse;
}
@keyframes bounce-in {
  0% {
    right: -15vw;
  }
  100% {
    right: 18vw;
  }
}

.bounce2-enter-active {
  animation: bounce2-in 0.5s;
}
.bounce2-leave-active {
  animation: bounce2-in 0.3s reverse;
}
@keyframes bounce2-in {
  0% {
    right: -15vw;
  }
  100% {
    right: 0;
  }
}
</style>
