<template>
  <div class="w-40 h-40 relative">
    <label
      class="mx-auto flex flex-col w-max items-center w-max-64 h-max-64 bg-gray text-blue relative rounded-full  shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white"
    >
      <img
        v-if="userInfo.avatar"
        class="object-cover w-40 h-40 rounded-full"
        :src="userInfo.avatar"
      />
      <img v-else class="object-cover w-40 h-40 rounded-full" :src="pic" />
      <input type="file" class="hidden" @change="onHandleChange" />
    </label>
  </div>
</template>

<script>
import UploadService from "../../../services/upload.service.js";
import pic from "@/assets/img/basic_image.png";
import SessionService from "../../../services/session.service.js";

export default {
  data: function() {
    return {
      UserNicName: "",
      ds: [],
      pic: pic,
      stage: String,
    };
  },
  created: function() {
    this.UserNicName = SessionService.getNickname();
  },
  computed: {
    inputVal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
  },
  props: ["modelValue:String", "address", "userInfo", "sub_bucket"],
  emits: ["update:modelValue", "options"],
  methods: {
    onHandleChange(event) {
      // console.log('event.target.files', event.target.files);
      //   console.log("MediaInfo:::", MediaInfo)
      //   console.log('file received', file);
      this.stage = `${process.env.VUE_APP_STAGE}`;
      const file = event.target.files[0];

      MediaInfo(
        {
          format: "JSON",
        },
        (mediaInfo) => {
          if (file) {
            const reader = new FileReader();
            const getSize = () => file.size;
            const readChunk = (chunkSize, offset) =>
              new Promise((resolve, reject) => {
                reader.onload = (event) => {
                  if (event.target.error) {
                    reject(event.target.error);
                  }
                  resolve(new Uint8Array(event.target.result));
                };
                reader.readAsArrayBuffer(
                  file.slice(offset, offset + file.size)
                );
              });

            mediaInfo
              .analyzeData(getSize, readChunk)
              .then((info) => {
                // this.$refs.ta.value = JSON.stringify(result, null, 2);
                console.log("result:::", info);
                console.log("address !!!!!!", this.address);
                console.log("usernickname ==> ", this.UserNicName);
                // UploadService.media(file, this.address, info, reader).then(
                UploadService.media(
                  file,
                  this.UserNicName,
                  this.sub_bucket,
                  info,
                  reader,
                  this.stage
                ).then((res) => {
                  console.log("uploaded !!! :", res);
                  // const imgPath = "https://parular-contents.s3.ap-northeast-2.amazonaws.com/" + res.path;

                  let imgPath = "";

                  if (this.stage === "prod") {
                    imgPath =
                      "https://cilo-nft-prod-contents.s3.ap-northeast-2.amazonaws.com/" +
                      res.path;
                  } else {
                    imgPath =
                      "https://cilo-nft-staging-contents.s3.ap-northeast-2.amazonaws.com/" +
                      res.path;
                  }

                  // this.$emit('changed', imgPath)
                  this.inputVal = imgPath;
                  // this.userInfo.avatar = this.inputVal;
                  // this.userInfo.avatar_min = this.inputVal;
                  this.$emit("uploaded", {
                    id: res.id,
                    name: res.name,
                    path: imgPath,
                    done: true,
                  }),
                    this.$emit("update");
                });
              })
              .catch((error) => {
                console.error(error);
              });

            // UserService.update(
            //   this.userInfo.nickname,
            //   this.userInfo.avatar,
            //   this.userInfo.avatar_min
            // )
            //   .then((res) => {
            //     console.log("아바타 저장완료", res);
            //   })
            //   .catch((e) => {});
          }
        }
      );
    },
  },
};
</script>
