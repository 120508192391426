<template>
  <div>
    <section class="profile__bg py-20">
      <user-section :UserData="UserData" />
    </section>

    <section class="profile__bg">
      <tab-section class="adjustment" @change-tab="changeTab" />
    </section>

    <section class="adjustment pt-20">
      <artworks-section v-if="tab === 'artworks'" />
      <sales-section v-else-if="tab === 'sales'" />
      <bids-section v-else-if="tab === 'bids'" />
    </section>

    <notifications position="bottom right" />
  </div>
</template>

<script>
// section
import UserSection from "@/views/my-page/section/UserSection.vue";
import TabSection from "@/views/my-page/section/TabSection.vue";
import ArtworksSection from "@/views/my-page/section/ArtworksSection.vue";
import SalesSection from "@/views/my-page/section/SalesSection.vue";
import BidsSection from "@/views/my-page/section/BidsSection.vue";

// services
import OpenapiService from "@/services/openapi.service";
import SessionService from "@/services/session.service";

// common
import { notify } from "@kyvg/vue3-notification";

export default {
  data: function() {
    return {
      tab: "artworks",
      UserData: {},
      userNickname: null,
      userId: null,
    };
  },

  components: {
    UserSection,
    TabSection,
    ArtworksSection,
    SalesSection,
    BidsSection,
  },

  created: async function() {
    this.userNickname = await SessionService.getNickname();
    this.userId = await SessionService.getUserId();
    this.loadUser();
  },

  methods: {
    loadUser() {
      OpenapiService.userById(this.userId)
        .then((res) => {
          this.UserData = res.data;
        })
        .catch((error) => {
          const msg = `Could not load user data. Error: ${error.response.data}`;
          notify({ type: "error", text: msg });
        });
    },

    changeTab(event) {
      this.tab = event;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_layout.scss";
.profile__bg {
  background-color: #f8f8f8;
}

.adjustment {
  margin: auto;
  @include sm {
    width: 90%;
  }

  @include md {
    width: 80%;
  }

  @include lg {
    width: 80%;
  }

  @include xl {
    width: calc(100% - 288px);
  }

  @include xxl {
    width: 1440px;
  }
}
</style>
